<template>
  <div id="PayAfter">
    <main class="main-box">
      <div class="main">
        <h3 class="mb-20">当前状态</h3>

        <div v-if="InvoiceData.orderState === 0" class="top">
          <!-- <el-tag type="primary" effect="dark" class="PayState">待支付</el-tag> -->
          <div>
            <div class="text-secondary">
              请在
              <span class="text-primary">{{ min }}:{{ second }}</span>
              s内完成付款
            </div>
            点击这里
            <el-button type="text" @click="ToPay">付款</el-button>
            ； 如果您不想购买，可以
            <el-button type="text" @click="cancelOrder">取消订单</el-button>
          </div>
        </div>

        <div v-if="InvoiceData.orderState === 1" class="top">
          <!-- <el-tag type="success" effect="dark" class="PayState">支付完成</el-tag> -->
          <span>
            您的订单已完成；
            <!-- 您的订单已完成；如果您想开具发票，可以
            <el-button type="text">开发票</el-button> -->
          </span>
        </div>

        <div v-if="InvoiceData.orderState === 2" class="top">
          <!-- <el-tag type="primary" effect="dark" class="PayState">已取消</el-tag> -->
          <span>
            您的订单已取消；如果您想重新购买，可以
            <el-button type="text" @click="rebug">重新购买</el-button>
          </span>
        </div>

        <hr class="mb-20" />

        <h3 class="mb-20">订单信息</h3>

        <div class="content">
          <img :src="courseData.courseCover" alt="" />
          <div>
            <!--课程名称-->
            <p class="name">{{ courseData.courseName }}</p>
            <!--金额-->
            <p class="price">
              ￥
              <span>{{ courseData.coursePrice }}</span>
            </p>
          </div>
        </div>

        <div class="total">
          <h3 class="mb-20">订单金额</h3>

          <p class="mb-10">
            课程金额
            <span class="ml-20">
              ￥
              <span>{{ courseData.coursePrice }}</span>
            </span>
          </p>

          <p class="mb-20">
            应付金额
            <span class="ml-20">
              ￥
              <span>{{ courseData.coursePrice }}</span>
            </span>
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import API from '@/api';

export default {
  name: 'PayAfter',
  data() {
    return {
      courseId: {
        id: '',
      },
      courseData: '',
      invoiceId: {
        id: '',
      },
      InvoiceData: '',
      min: Number,
      second: Number,
    };
  },
  created() {
    this.getDetail();
    this.getState();
  },
  mounted() {},

  methods: {
    // 倒计时
    countDown() {
      if (this.InvoiceData.createDate === 0 && this.InvoiceData.orderState === 0) {
        this.$confirm('该订单已超时！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.getDetail();
            this.getState();
          })
          .catch(() => {
            this.getDetail();
            this.getState();
          });
        return;
      }
      if (this.InvoiceData.orderState === 0) {
        const min = parseInt((this.InvoiceData.createDate / 60) % 60, 10);
        this.min = min < 10 ? `0${min}` : min;
        const second = parseInt(this.InvoiceData.createDate % 60, 10);
        this.second = second < 10 ? `0${second}` : second;
        if (min >= 0 && second >= 0) {
          // 倒计时结束关闭订单
          if (min === 0 && second === 0) {
            this.$confirm('该订单已超时！', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
            }).then(() => {
              this.getDetail();
              this.getState();
            });
            return;
          }
          setTimeout(() => {
            this.InvoiceData.createDate -= 1;
            this.countDown();
          }, 1000);
        }
      }
    },

    // 去付款
    ToPay() {
      this.$router.push({
        path: '/Pay',
        query: {
          id: this.$route.query.courseId,
          orderId: this.$route.query.orderId,
          orderCode: this.$route.query.orderCode,
        },
      });
    },
    // 重新购买
    rebug() {
      this.$router.push({
        path: '/',
        query: { id: this.$route.query.courseId },
      });
    },
    cancelOrder() {
      // 取消弹窗
      this.$confirm('确定要取消当前课程的购买吗?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          API.cancel({
            id: this.$route.query.orderId,
          }).then((res) => {
            if (res.message.success === true) {
              this.getDetail();
              this.getState();
              this.$message({
                message: '已取消购买',
                type: 'success',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    getDetail() {
      this.courseId.id = this.$route.query.courseId;
      API.Detail(this.courseId).then((res) => {
        if (res.message.success === true) {
          this.courseData = res.message.data;
        }
      });
    },
    getState() {
      this.invoiceId.id = this.$route.query.orderId;
      API.state(this.invoiceId).then((res) => {
        this.InvoiceData = res.message.data;
        this.countDown();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  width: 1200px;
  height: 80vh;
  margin: auto;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .PayState {
      width: 160px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin-right: 20px;
    }
  }
  .main {
    height: 80vh;
    padding: 60px 30px;
  }
  .content {
    display: flex;
    margin-bottom: 30px;

    img {
      width: 250px;
      height: 150px;
      margin-right: 30px;
      float: left;
    }
    .name {
      line-height: 22px;
      height: 66px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .price {
      font-size: 30px;
      color: #f7b515;
    }
  }
}
</style>
